$(function(){
    $('#mv-slider').slick({
        autoplay: true,
        dots: true,
        arrows: true,
        fade: true,
        slidesToShow: 1,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplaySpeed: 2000,
        speed: 1000
    });
});

// $(function(){
//     function zeroPadding(num) {
//         return ('00' + num).slice(-2);
//     }
//     $('#classes-slider').on('init', function(event, slick) {
//         $(this).append('<div class="slick-counter"><span class="current"></span> / <span class="total"></span></div>');
//         $('.current').text(zeroPadding(slick.currentSlide + 1));
//         $('.total').text(zeroPadding(slick.slideCount));
//     })
//     .slick({
//         autoplay: true,
//         arrows: true,
//         infinite: true,
//         // slidesToShow: 3,
//         // slidesToScroll: 1,
//         centerMode: true,
//         centerPadding: '36%',
//         prevArrow: '<button class="slick-prev slide-arrow arrow-prev"></button>',
//         nextArrow: '<button class="slick-next slide-arrow arrow-next"></button>',
//         responsive:[
//             {
//                 breakpoint: 768,
//                 settings:{
//                     slidesToShow: 1,
//                     centerPadding: '0',
//                 }
//             }
//         ]
//     })
//     .on('beforeChange', function(event, slick, currentSlide, nextSlide) {
//         $('.current').text(zeroPadding(nextSlide + 1));
//     });
// });

$(function(){
    var headerHeight = $('.p-header').outerHeight();
    var urlHash = location.hash;
    if(urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function(){
            var target = $(urlHash);
            var position = target.offset().top - headerHeight;
            $('body,html').stop().animate({scrollTop:position}, 500);
        }, 100);
    }
    $('[data-navTo]').click(function() {
        var href= $(this).attr('href');
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, 500);   
    });
});

$(function(){
    window.sr = ScrollReveal();
    sr.reveal('.feedIn-01', {
        origin: 'bottom',
        distance: '0%',
        easing: 'ease-in-out',
        duration: 3000,
        opacity: 0,
        scale: 1
    });
    sr.reveal('.feedIn-02', {
        origin: 'bottom',
        distance: '30px',
        easing: 'ease-in-out',
        duration: 2000,
        opacity: 0,
        scale: 1
    });
});

$(function() {
    objectFitImages();
});
